<template>
  <navigation-layout
    :category="category"
    :entries="navigationEntries"
    :footer-entries="navigationFooter">
    <component
      v-if="!loading"
      :category="category"
      :is="layoutComponent"
      :splitview-mode="true"
      :wide="showInFullscreen"
      :breadcrumb="hasBreadcrumb"
      :child-components="components">
      <component
        v-if="!loading"
        :breadcrumb="hasBreadcrumb"
        :is="components[0].vueComponent"
        :category="components[0].category"
        :splitview-mode="components[0].layout === 'splitview'">
      </component>
    </component>
  </navigation-layout>
</template>

<script>
  import DesignMixin from '../mixin/design'
  import {defineAsyncComponent, markRaw} from 'vue'

  export default {
    computed: {
      hasBreadcrumb() {
        return this.$helper.findAttributeValue(this.category, 'CMS-Configuration')?.includes('Breadcrumb')
      },
      showInFullscreen() {
        return this.components[0]
          && this.components[0].showInFullscreen
      },
    },
    data() {
      return {
        loading: false,
        category: null,
        components: [],
        layoutComponent: null,
        navigationEntries: [],
        navigationFooter: [],
      }
    },
    methods: {
      /**
       * Resolves the correct component to show contents
       * @param category
       */
      async resolveComponent(category) {
        let layout = 'page'
        let components = []
        // Determine the current layout
        // (Page = CMS-Template-Attribute === 'Page' OR null)
        // (SplitView = CMS-Template-Attribute === 'SplitView')
        let templateAttr = category.attributes?.find(attr => attr.name === 'CMS-Template')
        switch (templateAttr?.value?.toLowerCase()) {
          case 'splitview':
            layout = 'splitview'
            let childCategories = await this.$api.findCategories(category.id)
            if (childCategories) {
              for (let i = 0; i < childCategories.length; i++) {
                let childCategory = childCategories[i]
                if (childCategory) {
                  let component = this.$helper.resolveComponentTypeByCategory(childCategory, templateAttr)
                  if (component) {
                    components.push({
                      category: childCategory,
                      component,
                    })
                  }
                }
              }
            }
            break
          case 'page':
          default:
            layout = 'page'
            components[0] = {
              component: this.$helper.resolveComponentTypeByCategory(category, templateAttr),
              category
            }

            if (layout === 'page'
              && category.childrencount > 0
              && components[0].component === 'page') {
              layout = 'homepage'
            }
            break
        }

        return {
          layout,
          components
        }
      },
      /**
       * Retrieve the parent category for icon and background
       * and colors. Set the icon for navigation bar
       * @return {Promise<NavigationFailure | void | undefined>}
       */
      async resolveParentCategory(categorySlug) {
        let categoryId = this.$helper.extractIdFromSlug(categorySlug)
        if (categoryId) {
          // Apply the current design based on the MAM
          // category (icon, images and colors)
          this.category = await this.applyDesign(categoryId)

          // Creates the top bar navigation
          await this.buildNavigation()
          await this.buildFooter()

          // Update the UI Parmas
          this.$emit('updateUI')
        }
      },
      /**
       * Retrieve child categories for create the navigation
       * It is everytime created of the first level of the
       * backend MAM structure
       */
      async buildNavigation() {
        if (!this.navigationEntries || this.navigationEntries.length === 0) {
          this.navigationEntries = await this.$api.findCategories(this.$helper.extractIdFromSlug(this.$route.params.slug))
          this.navigationEntries = this.navigationEntries.map(cat => {
            return {
              label: cat.categoryname,
              link: '/' + this.$route.params.slug + '/' + this.$helper.slugify(cat.categoryname) + '-' + cat.id,
            }
          })
          this.callDefaultRoute()
        }
      },
      /**
       *
       * @return {Promise<void>}
       */
      async buildFooter() {
        const footerAttr = this.$helper.findAttributeValue(this.category, 'CMS-Footer')
        this.navigationFooter = []
        if (footerAttr) {
          const categoryIds = footerAttr.split(',')
          for (let i = 0; i < categoryIds.length; i++) {
            let categoryId = categoryIds[i]
            if (categoryId && categoryId.includes(':')) {
              categoryId = categoryId.split(':')[1]
              let category = await this.$api.findCategory(categoryId)
              if (category) {
                this.navigationFooter.push(category)
              }
            }
          }
        }
      },
      /**
       * Processes the current children of the parent category
       * ($route.params.pathMatch.shift()) and select one of the
       * components, which are based under @/components/views.
       * @return {Promise<void>}
       */
      async processCategory() {
        if (this.category) {
          const dynamicComponentTypes = await this.resolveComponent(this.category)
          switch (dynamicComponentTypes.layout) {
            case 'splitview':
              this.layoutComponent = markRaw(defineAsyncComponent(() => import('../components/layouts/content/SplitLayout')))
              break
            case 'homepage':
              this.layoutComponent = markRaw(defineAsyncComponent(() => import('../components/layouts/content/HomepageLayout')))
              break
            case 'page':
            default:
              this.layoutComponent = markRaw(defineAsyncComponent(() => import('../components/layouts/content/DefaultLayout')))
              break
          }
          let components = this.$helper.resolveComponents(dynamicComponentTypes.components, dynamicComponentTypes.layout)
          // TODO: Workaround (calling category once again to fetch all detail information)
          if (dynamicComponentTypes.layout === 'splitview') {
            components[0].category = await this.$api.findCategory(components[0].category.id)
          }
          this.components = components
        }
      },
      /**
       * Changes the meta title by changing route
       */
      changeMetaTitle() {
        let element = document.querySelector('head title')
        if (element
          && this.category
          && this.category.categoryname) {
          element.innerHTML = this.category.categoryname
        }
      },
      callDefaultRoute() {
        // Redirect to the first element if the page has not been set
        // Default value is the first element in MAM
        if (this.navigationEntries
          && this.navigationEntries.length > 0
          && !this.$route.params.pathMatch) {
          return this.go(this.navigationEntries[0].link)
        }
      }
    },
    /**
     * Retrieves the category based on the slug and
     * uses the colors to design the application in your primary,
     * secondary and tertiary color.
     * @return {Promise<void>}
     */
    watch: {
      '$route': {
        deep: true,
        immediate: true,
        async handler() {
          this.loading = true
          let categoryId = this.$route.params.slug
          if (this.$route.params.pathMatch) {
            categoryId = this.$route.params.pathMatch[this.$route.params.pathMatch.length - 1]
          }
          await this.resolveParentCategory(categoryId)
          await this.processCategory()
          this.changeMetaTitle()
          this.loading = false
        }
      }
    },
    inject: [
      '$api',
      '$helper',
    ],
    mixins: [
      DesignMixin
    ]
  }
</script>